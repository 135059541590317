.ta {
    &_c {
        text-align: center;
    }
    &_l {
        text-align-last: left;
    }
}

.text {
    font-size: 1.6rem;
    color: $color-dark-3;
}

.content-block {
    flex: 1 1 100%;
}

.link {
    font-size: inherit;
    color: $color-primary-blue;
}
