.auth {
    width: 100%;
    overflow-x: hidden;
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &__progressbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
        
    &__back {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        top: 1.2rem;
        left: 1.2rem;
        background-color: $color-white;
    }

    &-step {
        position: relative;
        width: 100%;
        padding-top: 8rem;
        //display: none;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: space-between;
        // transform: translateX(150%);
        transition: 0.5s;
        
        &.active {
            display: flex;
            // transform: translateX(0);
        }

        &.passed {
            display: none;
            // transform: translateX(-150%);
        }
    }

    &__title {
        max-width: 35rem;
        margin: 0 auto 3.2rem;
        display: flex;
        flex-direction: column;
        
        &_password {
            height: 5.6rem;
        }

        .text {
            margin-top: 1.2rem;
        }
    }
}

.bottom-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;

    &__details {
        margin-bottom: 1.6rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        font-weight: $font-weight-500;
        color: $color-gray-5;
    }
}
