.footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    min-width: 320px;
    // height: 8.4rem;
    background-color: $color-white;
    z-index: 50;
    box-shadow: 0px -1px 0px 0px #98A3B31F;

    &__actions {
        display: flex;
        align-items: center;
        padding: 1.2rem 0;

        .button-call {
            flex: 0 0 5.2rem;
            width: 5.2rem;
            height: 5.2rem;
            margin-left: 0.8rem;
            
            svg {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &__flex {
        min-width: 100%;
        height: 100%;
        padding: 0.8rem 0 3.2rem;
        display: flex;
        justify-content: space-around;
        // gap: 1.6rem;
        margin: 0 -0.8rem;
    }

    &__link {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0.8rem;
        cursor: pointer;

        svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-bottom: 0.2rem;
        }

        &:active svg {
            animation: push 0.3s linear;
        }

        span {
            font-size: 1.1rem;
            line-height: 1.4rem;
            font-weight: $font-weight-500;
        }

        &.active {
            color: $color-primary-blue;
        }
    }
}

@keyframes push {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}