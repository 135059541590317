.reviews {
	&__title {
		width: 100%;
	}
	&__title-box {
		margin-top: 2.4rem;
		display: flex;
		align-items: center;
		font-size: 2.4rem;
		line-height: 2.8rem;
		font-weight: $font-weight-650;
		color: $color-dark-5;

		svg {
			width: 2.4rem;
			height: 2.4rem;
			margin-right: 0.2rem;
			fill: $color-dark-5;
		}
	}

	&__rating {
		&:after {
			content: '·';
			display: inline-block;
			margin: 0 0.4rem;
		}
	}
}

.review {
	display: flex;
	flex-direction: column;
	padding: 3.2rem 0;
	border-bottom: 1px solid $color-gray-7;
	text-align: left;

	&:last-child {
		border-bottom: none;
	}

	&__title {
		display: flex;
		align-items: center;
		margin-bottom: 1.2rem;
	}

	&__user-photo {
		position: relative;
		width: 4rem;
		height: 4rem;
		border-radius: 4rem;
		margin-right: 0.8rem;

		img {
			width: 100%;
			border-radius: 4rem;
		}
	}

	&__source {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 1.8rem;
		height: 1.8rem;
		border-radius: 1.8rem;
		border: 0.2rem solid $color-white;

		img {
			width: 100%;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
      width: 100%;
	}

	&__user-name {
      width: 100%;
		margin-bottom: 0.3rem;
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: $font-weight-550;
		color: $color-dark;
	}

	&__details {
		display: flex;
      width: 100%;
	}

	&__rating {
		display: flex;
		height: 1.6rem;
		margin-right: 0.6rem;

		svg {
			width: 1.6rem;
			fill: $color-yellow;
		}

		&[data-stars="1"] {
			svg {
				&:nth-child(2) {
					fill: $color-gray-3;
				}
			}
		}

		&[data-stars="1"],
		&[data-stars="2"] {
			svg {
				&:nth-child(3) {
					fill: $color-gray-3;
				}
			}
		}

		&[data-stars="1"],
		&[data-stars="2"],
		&[data-stars="3"] {
			svg {
				&:nth-child(4) {
					fill: $color-gray-3;
				}
			}
		}

		&[data-stars="1"],
		&[data-stars="2"],
		&[data-stars="3"],
		&[data-stars="4"] {
			svg {
				&:nth-child(5) {
					fill: $color-gray-3;
				}
			}
		}
	}

	&__date {
		font-size: 1.3rem;
		line-height: 1.6rem;
		font-weight: $font-weight-500;
		color: $color-gray-3;
	}

	&__text {
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: $font-weight-450;
		color: $color-dark-4;

		&.sm-item-secondary {
			min-height: 88px;
        }
	}

	.LinesEllipsis-ellipsis {
		font-size: 1.6rem;
		line-height: 2.2rem;
		color: $color-primary-blue;
		cursor: pointer;
	}
}